<template>
  <div class="px-10">
    <v-row>
      <v-col>
        <span class="text-h4 mb-5 primary--text"><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->{{docType}}</span>
        <span class="primary--text ml-10">
          <v-icon class="mb-2" color="primary" left>mdi-file</v-icon> document
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn class="ml-5" small color="grey" outlined @click="close()">ปิดหน้าต่าง</v-btn>
        <!-- <v-btn class="ml-5" small color="primary" @click="close()">บันทึกข้อมูล</v-btn> -->

        <v-btn-toggle class="ml-5" mandatory>
          <v-btn color="primary" small @click="save()">บันทึกข้อมูล</v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs"  v-on="on"><v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
            <v-list dense>
              <v-list-item link @click="saveClose()">
                <v-list-item-title>บันทึกและปิด</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>

        <print-document :type="docType" :data="null">
          <v-btn class="ml-5" small color="primary" outlined>พิมพ์ <v-icon right>mdi-printer</v-icon></v-btn>
        </print-document>
        <v-btn class="ml-5" small color="error" outlined link :to="`/pages/document/${docPath}/pay/1`">จ่ายเงิน</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <span class="text-h4 dark--text mr-5">รวมจำนวนเงินทั้งสิ้น</span>
        <span class="text-h2 primary--text">4,500.00</span>
      </v-col>

      <v-col cols="3">
        <v-text-field name="" label="ชื่อโปรเจ็ค" outlined dense/>
        <v-text-field name="" label="ชื่อพนักงาน" outlined dense/>
        <v-text-field name="" label="เลยที่ใบอ้างอิง" value="q-202002" disabled outlined dense/>
      </v-col>
      <v-col cols="3" offset="6">
        <v-row>
          <v-col cols="5">
            <v-row class="border-white" dense>
              <v-col cols="12">วันที่ออกใบ</v-col>
              <v-col cols="12">วันครบกำหนด</v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row class="border-grey" dense>
              <v-col cols="6">12/09/2563</v-col> <v-col cols="6" class="pl-5">08:39</v-col>
              <v-col cols="12">12/09/2563</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="6">
        <v-card outlined>
          <v-card-text class="px-15">
            <div class="text-h5 primary--text mb-5">ข้อมูลรถ</div>

            <v-row>

              <v-col cols="4">
                <v-text-field name="" label="หมวดหมู่" outlined dense/>
              </v-col>
              <v-col cols="4">
                <v-text-field name="" label="เลขทะเบียน" outlined dense/>
              </v-col>
              <v-col cols="4">
                <v-text-field name="" label="จังหวัด" outlined dense/>
              </v-col>
              <!--  -->
              <v-col cols="12">
                <v-text-field name="" label="ประเภทรถ" outlined dense/>
              </v-col>
              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="น้ำหนักรถเปล่า" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-text-field name="" label="เครื่องยนต์ (CC)" outlined dense/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="วันจดทะเบียน" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-text-field name="" label="วันที่ภาษีขาด" outlined dense/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="วันที่จะยื่นต่อ" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-text-field name="" label="ปีภาษีใหม่ที่จะต่อ" outlined dense/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="12 mb-5">
                <div class="mb-5">ชนิดรถ</div>

                <v-btn color="white" class="mr-2 mb-5" :disabled="form.carType=='EV'" @click="form.carType='EV'">
                  <span class="text-h5 mr-2 cyan--text">EV </span>
                  <span class="text-h6 grey--text">100% แก๊ส</span>
                </v-btn>

                <v-btn color="white" class="mx-2 mb-5" :disabled="form.carType=='CNG'" @click="form.carType='CNG'">
                  <span class="text-h5 mr-2 green--text">CNG </span>
                  <span class="text-h6 grey--text">hybrid</span>
                </v-btn>

                <v-btn color="white" class="mx-2 mb-5" :disabled="form.carType=='LPG'" @click="form.carType='LPG'">
                  <span class="text-h5 mr-2 blue--text">LPG </span>
                  <span class="text-h6 grey--text">แก๊ส</span>
                </v-btn>

                <v-btn color="white" class="mx-2 mb-5" :disabled="form.carType=='PS'" @click="form.carType='PS'">
                  <span class="text-h6 grey--text">นิติบุคคล</span>
                </v-btn>

              </v-col>

              
            </v-row>
            
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-card-text class="px-15">
            <div class="text-h5 primary--text mb-5">ข้อมูลลูกค้า</div>

            <v-row>

              <v-col cols="6">
                <v-text-field name="" label="ชื่อลูกค้า" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-text-field name="" label="เบอร์โทร" outlined dense/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="เลขที่เสียภาษี" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-text-field name="" label="ที่อยู่" outlined dense/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="สาขา" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-radio-group class="mt-0" row dense>
                  <div class="mr-5">ชนิดเอกสาร : </div>
                  <v-radio name="" label="สำเนา" value="radio-1"></v-radio>
                  <v-radio name="" label="เล่มจริง" value="radio-2"></v-radio>
                  <v-spacer/>
                </v-radio-group>
              </v-col>
              <!--  -->

              <!--  -->
              <v-col cols="6">
                <v-text-field name="" label="กลุ่มลูกค้า/ตัวแทน" outlined dense/>
              </v-col>
              <v-col cols="6">
                <v-textarea name="" label="หมายเหตุลูกค้า" outlined dense rows="3"/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->

              <!--  -->
              <v-col cols="6">
                <v-checkbox class="mt-0" name="" label="ที่อยู่จัดส่ง"/>
                <v-checkbox class="mt-0" name="" label="โทรแจ้ง"/>
                <v-checkbox class="mt-0" name="" label="โทรเตือนต่ออายุ"/>
              </v-col>
              <v-col cols="6">
                <v-textarea name="" label="ที่อยู่จัดส่ง" outlined dense rows="3"/>
              </v-col>
              <!-- <v-col cols="4"></v-col> -->
              <!--  -->
              
            </v-row>
            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="px-15">
            <div class="text-h5 primary--text mb-5">ข้อมูลสินค้า</div>
            
            <div class="mb-5">
              <span class="text-h6">เลือกสินค้าด่วน</span>
                (ใช้ได้กับ รย.1 2 3 12 และ 17 เท่านั้น รย.อื่นนอกจากนี้ต้องเลือกสินค้าเอง)
              <span class="d-flex float-right">
                <v-text-field class="ml-5" name="" label="วันนัดรับเอกสาร" outlined dense hide-detail/>
                <v-text-field class="ml-5" name="" label="เวลารับ" outlined dense hide-detail/>
              </span>
            </div>
            
            <div>
                <v-checkbox class="mt-0" name="" label="ทั้งหมด"/>
            </div>
            <div class="ml-10 d-flex">
              <v-checkbox class="mr-10 mt-0" name="" label="ตรวจสภาพรถ"/>
              <v-checkbox class="mr-10 mt-0" name="" label="พ.ร.บ."/>
              <v-checkbox class="mr-10 mt-0" name="" label="ค่าภาษี"/>
            </div>

            <v-divider/>

            <v-card elevation="1" class="mt-5">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="light">
                    <tr>
                      <th class="text-center">พ.ร.บ./ ประเภทรถ</th>
                      <th class="text-center">วันเริ่มต้น</th>
                      <th class="text-center">เต็มปี</th>
                      <th class="text-center">วันสิ้นสุด</th>
                      <th class="text-center">ส่วนลด</th>
                      <th class="text-center">ราคารวม</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in desserts" :key="item.name">
                      <td>
                        <v-text-field class="mt-5" outlined dense/>
                      </td>
                      <td>
                        <v-text-field class="mt-5" outlined dense/>
                      </td>
                      <td>
                        <v-checkbox class="mt-1" dense/>
                      </td>
                      <td>
                        <v-text-field class="mt-5" outlined dense/>
                      </td>
                      <td>
                        <v-text-field class="mt-5" outlined dense/>
                      </td>
                      <td>
                        <v-text-field class="mt-5" outlined dense/>
                      </td>
                      <td class="text-center">
                        <v-btn color="error" class="mb-1" fab tile small elevation="0">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card elevation="0" class="mt-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="light">
                      <tr>
                        <th class="text-center">พ.ร.บ./ ประเภทรถ</th>
                        <th class="text-center">วันเริ่มต้น</th>
                        <th class="text-center">เต็มปี</th>
                        <th class="text-center">วันสิ้นสุด</th>
                        <th class="text-center">ส่วนลด</th>
                        <th class="text-center">ราคารวม</th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in desserts" :key="item.name">
                        <td>
                          <v-text-field class="mt-5" outlined dense/>
                        </td>
                        <td>
                          <v-text-field class="mt-5" outlined dense/>
                        </td>
                        <td>
                          <v-checkbox class="mt-1" dense/>
                        </td>
                        <td>
                          <v-text-field class="mt-5" outlined dense/>
                        </td>
                        <td>
                          <v-text-field class="mt-5" outlined dense/>
                        </td>
                        <td>
                          <v-text-field class="mt-5" outlined dense/>
                        </td>
                        <td class="text-center">
                          <v-btn color="error" class="mb-1" fab tile small elevation="0">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-card-text>
                  <v-row justify="space-between">
                    <v-col cols="4">
                      <v-btn color="primary" outlined>+ เพิ่มสินค้า</v-btn>
                      <v-textarea class="mt-10" label="หมายเหตุท้ายใบเสนอราคา" outlined dense/>
                    </v-col>
                    <v-col cols="6" md="5" lg="4" xl="3" class="pr-7 fz-3">
                      <div class="mb-3">
                        <span>รวมเป็นเงิน</span>
                        <span class="float-right">4,500</span>
                      </div>
                      <div class="mb-3">
                        <span>ส่วนลดรวม</span>
                        <span class="float-right">60</span>
                      </div>
                      <div class="mb-3">
                        <span>ราคาหลังหักส่วนลด</span>
                        <span class="float-right">4,440</span>
                      </div>
                      <div class="mb-3">
                        <span>ภาษีมูลค่าเพิ่ม 7%</span>
                        <span class="float-right">310.80</span>
                      </div>
                      <div class="mb-3">
                        <span>จำนวนเงินรวมทั้งสิ้น</span>
                        <span class="float-right">4750.80</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>

            </v-card>

            <div class="green--text fz-7 mt-10">บันทึกการชำระเงิน</div>

            <v-card elevation="1" class="mt-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="green">
                      <tr>
                        <th class="text-center white--text">วันที่ชำระ</th>
                        <th class="text-center white--text">ช่องทาง</th>
                        <th class="text-center white--text">รายละเอียด</th>
                        <th class="text-center white--text">ยอดรับชำระ</th>
                        <th class="text-center white--text">หมายเหตุ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in desserts" :key="item.name">
                        <td class="text-center">1</td>
                        <td class="text-center">2</td>
                        <td class="text-center">3</td>
                        <td class="text-center">4</td>
                        <td class="text-center">5</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row> 
    
  </div>

</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';

export default {
  components: {
    PrintDocument,
  },
  data: () => ({
    docType: 'ใบเสร็จรับเงิน',
    docPath: 'receipt',

    form: {
      carType: 'EV',
      status: 'active',
    },

    desserts: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
      },
    ]


  }),

  created () {
    if(this.isUpdate)
      this.getData();
  },

  methods: {
    getData() {
      // this.$route.params.id;
      console.log(this.$route.params.id);
    },
    async save() {
      this.loading = true;
      await this.$alertConfirm({title:`ยืนยันบันทึกข้อมูล ?`,text: ``}).then((result) => { 
        if(result.isConfirmed) {

          console.log('save()');
          // this.$router.push('/');

          this.loading = false;
        }
        else {
          this.loading = false;
        }
      });
    },
    close() {
      this.$router.push({name:`report-${docPath}`});
    },
    async saveClose() {
      await this.save();
      await this.close();
    },
    
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    }
  },
}
</script>
<style lang="scss">
.border-grey {
  border: solid rgb(211, 211, 211) 1px!important;
  border-radius: 5px;
  padding: 10px;
}
.border-white {
  border: solid rgb(255, 255, 255) 1px!important;
  border-radius: 5px;
  padding: 10px;
}
</style>
